'use client';

import * as React from 'react';
import classNames from 'classnames';
import Scrollable from '@/components/ui/Scrollable';
import { getScrollParent } from '@/components/helpers/getScrollParent';
import { ActiveLink } from '@/components/ui/ActiveLink/ActiveLink';
import Icon, { IconsAvailable } from '@/components/ui/Icon';
import styles from './styles.module.scss';

export interface ITabsItemProps {
  title: React.ReactNode;
  description?: React.ReactNode;
  descriptionIntent?: 'default' | 'danger' | 'warning' | 'success';
  active?: boolean;
  href?: string;
  exact?: boolean;
  onClick?: (e: React.SyntheticEvent) => void;
  disabled?: boolean;
  icon?: IconsAvailable;
}

export interface ITabsProps {
  items: ITabsItemProps[];
  theme?: 'default' | 'small' | 'well';
  layout?: 'default' | 'vertical' | 'grid';
  showArrows?: boolean;
  className?: string;
  stretch?: boolean;
  isRoutable?: boolean;
}

const RenderTabs: React.FunctionComponent<ITabsProps> = (props) => {
  const activeTabElement = React.useRef<HTMLAnchorElement>(null);

  React.useEffect(() => {
    if (activeTabElement && activeTabElement.current) {
      const el = activeTabElement.current;
      const parent = getScrollParent(el) as HTMLDivElement;
      if (!(parent instanceof Window)) {
        parent.scrollLeft = el.offsetLeft - parent.getBoundingClientRect().width / 2 + el.getBoundingClientRect().width / 2;
      }
    }
  }, [activeTabElement]);

  const TabContent: React.FunctionComponent<ITabsItemProps> = (r) => {
    return (
      <React.Fragment>
        {r.icon && (
          <Icon
            width={18}
            height={18}
            kind={r.icon}
          />
        )}
        <span className={styles['tabs__list__wrapper']}>
          <span className={styles['tabs__list__title']}>{r.title}</span>
          {r.description && (
            <span
              className={classNames(styles['tabs__list__description'], {
                [styles['intent-default']]: r.descriptionIntent === 'default' || !r.descriptionIntent,
                [styles['intent-danger']]: r.descriptionIntent === 'danger',
                [styles['intent-warning']]: r.descriptionIntent === 'warning',
                [styles['intent-success']]: r.descriptionIntent === 'success',
              })}
            >
              {r.description}
            </span>
          )}
        </span>
      </React.Fragment>
    );
  };

  return (
    <div
      className={classNames(
        styles['tabs'],
        {
          [styles['theme-default']]: props.theme === 'default' || !props.theme,
          [styles['theme-small']]: props.theme === 'small',
          [styles['theme-well']]: props.theme === 'well',
          [styles['stretch']]: props.stretch,

          [styles['layout-default']]: !props.layout || props.layout === 'default',
          [styles['layout-vertical']]: props.layout === 'vertical',
          [styles['layout-grid']]: props.layout === 'grid',
        },
        props.className,
      )}
    >
      <ul className={styles['tabs__list']}>
        {props.items.map((r, ri) => (
          <li key={ri}>
            {props.isRoutable ? (
              <ActiveLink
                href={r.href || ''}
                exact={r.exact}
                activeRef={activeTabElement}
              >
                <TabContent {...r} />
              </ActiveLink>
            ) : (
              <a
                href={r.href}
                onClick={r.onClick}
                draggable={false}
                aria-current={r.active}
                ref={r.active ? activeTabElement : undefined}
                aria-disabled={r.disabled}
              >
                <TabContent {...r} />
              </a>
            )}
          </li>
        ))}
      </ul>
    </div>
  );
};

const Tabs: React.FunctionComponent<ITabsProps> = (props) => {
  if (props.items.length < 2) {
    return null;
  }

  if (!props.layout || props.layout === 'default') {
    return (
      <Scrollable
        hideScrollbar={true}
        hideShadows={true}
        hideArrows={!props.showArrows}
        className={classNames(styles['tabs__scroller'], { [styles['has-arrows']]: props.showArrows })}
      >
        <RenderTabs {...props} />
      </Scrollable>
    );
  }

  return <RenderTabs {...props} />;
};

Tabs.displayName = 'Tabs';

export default Tabs;
