'use client';

import * as React from 'react';

export const useIntersect = ({
  root,
  rootMargin,
  threshold,
  deps,
}: {
  root: Element | null;
  rootMargin: string;
  threshold: number[];
  deps?: React.DependencyList;
}) => {
  const node = React.useRef<HTMLDivElement>(null);
  const [entry, updateEntry] = React.useState<IntersectionObserverEntry | null>(null);

  if (typeof window !== 'undefined') {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const observer = React.useRef(
      new window.IntersectionObserver(
        ([e]) => {
          updateEntry(e);
        },
        {
          root,
          rootMargin,
          threshold,
        },
      ),
    );

    // eslint-disable-next-line react-hooks/rules-of-hooks
    React.useEffect(() => {
      const { current: currentObserver } = observer;
      currentObserver.disconnect();

      const n = node.current;
      if (n) {
        currentObserver.observe(n);
      }

      return () => {
        currentObserver.disconnect();
      };
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [node, ...(deps || [])]);
  }

  return {
    node,
    entry,
  };
};
