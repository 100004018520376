'use client';

import * as React from 'react';
import { useIntersect } from './useIntersect';

const usePreAndPostIntersect = (
  props: { root: Element | null; rootMargin: string; threshold: number[]; deps?: React.DependencyList } = {
    root: null,
    rootMargin: '0px',
    threshold: Array.from(Array(100).keys(), (i) => i / 100),
  },
) => {
  const { root, rootMargin, threshold, deps } = props;

  const pre = useIntersect({
    root,
    rootMargin,
    threshold,
    deps,
  });

  const post = useIntersect({
    root,
    rootMargin,
    threshold,
    deps,
  });

  return {
    pre,
    post,
  };
};

export default usePreAndPostIntersect;
