'use client';

import * as React from 'react';

const useIntersectionRatio = (
  intersection: {
    node: React.RefObject<HTMLDivElement> | null;
    entry: IntersectionObserverEntry | null;
  },
  func: (intersecting: boolean) => void,
) => {
  React.useEffect(() => {
    if ((intersection?.entry?.intersectionRatio || 0) > 0) {
      func(false);
    } else {
      func(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [intersection, func]);
};

export default useIntersectionRatio;
