'use client';

import * as React from 'react';
import classNames from 'classnames';
import Icon, { IconsAvailable } from '@/components/ui/Icon';
import { useIntersect } from '@/components/hooks/useIntersect';
import styles from './styles.module.scss';

export interface IAnimationsProps {
  animation: IconsAvailable;
  className?: string;
}

const Animations: React.FC<IAnimationsProps> = (props) => {
  const [animating, setAnimating] = React.useState(false);

  const elem = useIntersect({
    root: null,
    rootMargin: '0px',
    threshold: Array.from(Array(100).keys(), (i) => i / 100),
  });

  React.useEffect(() => {
    if (elem.entry && elem.entry.intersectionRatio > 0.5) {
      setAnimating(true);
    } else {
      if (animating) {
        setAnimating(false);
      }
    }
  }, [animating, elem]);

  return (
    <div
      className={classNames(styles['animations'], [styles[props.animation]], { [styles['animating']]: animating }, props.className)}
      ref={elem.node}
    >
      <Icon
        width={512}
        height={512}
        kind={props.animation}
      />
    </div>
  );
};

Animations.displayName = 'Animations';

export default Animations;
